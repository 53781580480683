<template>
  <div
    v-if="data[0] && data[0].data.position"
    class="picking-hints full-width absolute-center"
    :class="{
      message__center: data[0] && data[0].data.position === 'center',
      message__bottom: data[0] && data[0].data.position === 'bottom',
      message__top: data[0] && data[0].data.position === 'top',
    }"
  >
    <p
      v-for="(message, i) in data"
      :key="i"
      class="q-my-xs rounded"
      :class="message.data.bg ? 'bg-' + message.data.bg : ''"
    >
      {{ $t(message.data.text) }}
    </p>
  </div>

  <div v-else>
    <picking-message
      v-for="(message, i) in data"
      :key="i"
      :data="message.data"
    />
  </div>
</template>

<script>
export default {
  name: 'DynamicMessageCollection',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style>
.picking-hints {
  text-align: center;
  font-size: 1rem;
  animation: fadein 1.5s infinite;
}
.message__bottom {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 30px;
  position: fixed;
  z-index: -1;
}
.message__top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
}
</style>
